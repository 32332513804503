<template>
  <div class="trailerMainLayout">
    <div :class="[localDisplayLang === 'ara' ? 'trailerCardsAra' : 'trailerCards']">
             <div v-if="!trailers" class="error-message">
          <p> {{$t("No contents found")}} </p>
        </div>
     
      <div v-else class="trailerCard" v-for="(trailer, index) in trailers" :key="index" @click="playTrailer(index, trailer)">
        <div class="trailerContainer">
          <div class="trailerPosterLayout">
            <img :src="getPoster()" class="trailerPoster" alt="" />
          </div>
          <img class="play-img" src="@/assets/icons/Ic_Play1.svg" alt="" />
          <div class="trailerContentLayout">
            <div class="trailerContentSplitter">
              <p class="trailerTitle">{{ contentDetails.title }}</p>
              <p class="trailerDuration">
                {{ calcTimeFormat(trailer.duration) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { vLivePlayer, vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { mapGetters } from 'vuex';
export default {
  props: {
    contentDetails: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      trailers: null,
    };
  },

  computed: {
    ...mapGetters(["appConfig"]),

    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.trailers = this.contentDetails.trailer;
    //this.fetchtrailerList(this.contentDetails.objectid,this.seasonNum);
    this.trailerDeeplinkPlayback();
  },
  mounted() {
    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });
  },
  methods: {
    getPoster() {
      let content = this.contentDetails;
      if (this.contentDetails.trailer && this.contentDetails.trailer.poster && this.contentDetails.trailer.poster.filelist) {
        return this.contentDetails.trailer.poster.filelist[0].filename
      } 
      if (
        content &&
        content.poster &&
        content.poster.length &&
        !content.inwatchlist
      ) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
    fetchtrailerList(seriesId, seasonNum) {
      //let pageSize = this.appConfig.defaultpageSize;
      //let pageNum = Math.floor(this.trailerList.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: seriesId,
        seasonnum: seasonNum,
        //pagesize: pageSize,
        //page: pageNum,
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }
      eventBus.$emit("get-trailer-list", payload);
    },
    playTrailer(index = 0, singleTrailer) {
      this.contentDetails.trailerUrl = this.contentDetails.trailer[index].filelist[0].filename;

      this.showPlayer();
      let contentList = [this.contentDetails];
      this.initTrailerPlayback(contentList);

      let locationstr = location.pathname.split("/");

      window.history.pushState(
        "object or string",
        "",
        "/" +
          locationstr[1] +
          "/" +
          locationstr[2] +
          "/" +
          locationstr[3] +
          "/trailer/" +
          singleTrailer.trailerid +
          "/play"
      );
    },
    trailerDeeplinkPlayback() {
      if (this.$route.params.trailerId && this.$route.params.isPlayBackActive == "play") {
        const filterTrailerData =
          Array.isArray(this.trailers) &&
          this.trailers.filter((trailer) => {
            if (trailer.trailerid == this.$route.params.trailerId) {
              return trailer;
            }
          });
        if (filterTrailerData.length) {
          this.contentDetails.trailerUrl = filterTrailerData[0].filelist[0].filename;
          this.showPlayer();
          let contentList = [this.contentDetails];
          this.initTrailerPlayback(contentList);
        }
      }
    },
    initTrailerPlayback(contentList) {
      this.loadContentMetaData(contentList[0], null);

      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: isTrailer ? false : content.isDrmContent,
        position: contentHead,
        contentTags: content.tags,
        scrubbing: content.scrubbing ?content.scrubbing[0] : '',
        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre
        }
      });

      return playlistItem;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
  },
  components: {},
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./trailerCard.scss"
</style>
